import React, {Component} from "react";
import './AccountRequestDialog.css';

//Components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from '@mui/icons-material/Close';
import ReactTooltip from "react-tooltip";
import DialogContent from "@mui/material/DialogContent";
import YesCancelDialog from "../../../reusable/YesCancelDialog/YesCancelDialog";
import TextField from "@mui/material/TextField";
import {TextareaAutosize} from "@mui/material";
import ToolButton from "../../../reusable/ToolButton/ToolButton";
import api from "../../../api";


class AccountRequestDialog extends Component {

    state = {
        email:null,
        company:null,
        name:null,
        station:null,
        message:null
    }

    companyOnChangeHandler = (e) => {
        this.setState({company:e.target.value});
    }

    nameOnChangeHandler = (e) => {
        this.setState({name:e.target.value});
    }

    emailOnChangeHandler = (e) => {
        this.setState({email:e.target.value});
    }

    stationOnChangeHandler = (e) => {
        this.setState({station:e.target.value});
    }

    messageOnChangeHandler = (e) => {
        this.setState({message:e.target.value});
    }

    requestAccountOnClickHandler = () => {
        if(this.state.email && this.state.name && this.state.company) {
            this.requestAccount();
        }else{
            this.props.showMessage(2,"Das Unternehmen, die E-Mail und der Vor- und Nachname darf nicht leer sein");
        }
    }

    requestAccount = () => {
        let accountRequest = {
            mailToken:"AF3hZVPpgv4nT!ty",
            email:this.state.email,
            company:this.state.company,
            name:this.state.name,
            station:this.state.station,
            message:this.state.message
        }
        api.post("/mail/account-request",accountRequest)
            .then(response => {
                this.props.close();
                this.props.showMessage(0,"Die Accountanfrage wurde versandt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    render() {
        return (
            <Dialog scroll='body' fullWidth maxWidth="xs" open={this.props.open} onClose={this.props.close} className='account-request-dialog'>
                <ReactTooltip/>

                <YesCancelDialog
                    open={this.state.showSaveTripRequestDialog}
                    close={this.closeSaveTripRequestDialog}
                    header='Anfragen'
                    text='Wollen Sie die Fahrt wirklich anfragen? Änderungen sind dann nicht mehr möglich.'
                    onClick={this.state.trip && this.state.trip.id ? () => this.props.updateTripRequest(this.state.trip) : () => this.props.createTripRequest(this.state.trip)}
                />

                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle>Account anfragen</DialogTitle>
                <DialogContent>
                    <TextField className="field" value={this.state.company} placeholder="Unternehmen" onChange={this.companyOnChangeHandler}/>
                    <TextField className="field" value={this.state.email} placeholder="E-Mail Adresse" onChange={this.emailOnChangeHandler}/>
                    <TextField className="field" value={this.state.name} placeholder="Vor- und Nachname" onChange={this.nameOnChangeHandler}/>
                    <TextField className="field" value={this.state.station} placeholder="Station (optional)" onChange={this.stationOnChangeHandler}/>
                    <TextField style={{width:"100%"}} className="multiline" multiline value={this.state.message} placeholder="Nachricht (optional)" onChange={this.messageOnChangeHandler}/>
                </DialogContent>
                <DialogActions>
                    <ToolButton main onClick={this.requestAccountOnClickHandler}>Anfragen</ToolButton>
                </DialogActions>
            </Dialog>
        )
    }
}

export default AccountRequestDialog;