import React, {Component} from "react";
import './Login.css';

//Components
import ToolButton from "../../reusable/ToolButton/ToolButton";
import logo from './../../images/logo.png';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import BusinessIcon from '@mui/icons-material/Business';
import AccountRequestDialog from "./AccountRequestDialog/AccountRequestDialog";

class Login extends Component {

    state = {
        accountRequestDialog:false
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.props.loginClick();
        }
    }

    showAccountRequestDialog = () => {
        this.setState({accountRequestDialog:true});
    }

    closeAccountRequestDialog = () => {
        this.setState({accountRequestDialog:false});
    }

    render() {
        return (
            <div onKeyDown={this._handleKeyDown} className='login'>

                <AccountRequestDialog
                    open={this.state.accountRequestDialog}
                    close={this.closeAccountRequestDialog}
                    showMessage={this.props.showMessage}
                />

                <div className='login-div'>
                    <img className='logo' src={logo} alt="Unternehmenslogo"/>
                    <div className='input-div'>
                        <BusinessIcon className='input-icon'/>
                        <input maxLength="5" className='input-field' placeholder='Unternehmenskürzel (5-stellig)' onChange={this.props.loginAbbreviationChangeHandler} defaultValue={localStorage.getItem('abbreviation')}/><br/>
                    </div>
                    <div className='input-div'>
                        <PersonIcon className='input-icon'/>
                        <input className='input-field' placeholder='Benutzername' onChange={this.props.loginUsernameChangeHandler}/><br/>
                    </div>
                    <div className='input-div'>
                        <LockIcon className='input-icon'/>
                        <input className='input-field' placeholder='Passwort' type='password' onChange={this.props.loginPasswordChangeHandler}/><br/>
                    </div>
                    <ToolButton onClick={this.props.loginClick} main className='login-button'>Anmelden</ToolButton>
                    <ToolButton onClick={this.showAccountRequestDialog} className='login-button'>Account anfragen</ToolButton>
                    <ToolButton onClick={() => window.open('https://www.medimobil-fahrservice.de/', '_blank')} className='login-button'>Erklärvideo</ToolButton>
                </div>
            </div>
        )
    }
}

export default Login;