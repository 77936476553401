import React, {Component} from "react";
import './Message.css';

//Components
import Paper from "@mui/material/Paper";

class Message extends Component {

    render() {

        let messageTextStyle = null;

        if(this.props.company.abbreviation === this.props.message.senderAbbreviation && this.props.user.username === this.props.message.senderName) {
            messageTextStyle = {backgroundColor:'#FFB900',right:"0",float:"right"};
        }else{
            messageTextStyle = {backgroundColor:'lightgray',left:"0",float:"left"};
        }

        return (
            <div className="message">
                <Paper className="message-text" elevation={2} style={messageTextStyle}>
                    <p>
                        {this.props.message.text}
                    </p>
                    <p className="date-info">{this.props.formatDateTime(this.props.message.creationTimestamp)}</p>
                </Paper>
            </div>
        )
    }
}

export default Message;